.sec--reverse {
  flex-direction: row-reverse;
}

.text {
  text-align: left;
  max-width: 42vw;
}

.smartphoneImage {
  height: 75vh;
  max-width: 80vw;
  object-fit: contain;
}

.sec {
  .heading {
    // font-size: 3.5rem;
    --f-size: 3.8rem;
    font-size: var(--f-size);
    line-height: calc(var(--f-size) - 2px);
    margin: 50px 0;
    color: rgba($color: #ffffff, $alpha: 0.9);
    letter-spacing: -2px;
    font-weight: 500;
  }

  .subheading {
    --f-size: 1.25rem;
    font-size: var(--f-size);
    color: #e1e1e1;
    letter-spacing: 1px;
    line-height: calc(var(--f-size) + 10px);
  }
  // max-width: ;
}

@media (max-width: 1200px) {
  .sec--reverse {
    flex-direction: column-reverse;
    justify-content: space-around;
    text-align: center;
  }

  .smartphoneImage {
    height: 50vh;
  }

  .sec {
    .heading {
      font-size: 2rem;
      line-height: 2.2rem;
      margin: 20px 0;
      text-align: left;
    }

    .subheading {
      font-size: 1.2rem;
      text-align: left;
    }
    .text {
      text-align: left;
      max-width: 80vw;
    }
  }
}

#reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 0.5s transform ease-out;
}

#reveal.active {
  transform: translateY(0);
  opacity: 1;
}
