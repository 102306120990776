.sec--reverse {
  flex-direction: row-reverse;
}

.text {
  text-align: left;
  max-width: 42vw;
}

.smartphoneImage {
  object-fit: contain;
  max-width: 80vw;
  height: 75vh;
}

.sec .heading {
  --f-size: 3.8rem;
  font-size: var(--f-size);
  line-height: calc(var(--f-size)  - 2px);
  color: #ffffffe6;
  letter-spacing: -2px;
  margin: 50px 0;
  font-weight: 500;
}

.sec .subheading {
  --f-size: 1.25rem;
  font-size: var(--f-size);
  color: #e1e1e1;
  letter-spacing: 1px;
  line-height: calc(var(--f-size)  + 10px);
}

@media (width <= 1200px) {
  .sec--reverse {
    text-align: center;
    flex-direction: column-reverse;
    justify-content: space-around;
  }

  .smartphoneImage {
    height: 50vh;
  }

  .sec .heading {
    text-align: left;
    margin: 20px 0;
    font-size: 2rem;
    line-height: 2.2rem;
  }

  .sec .subheading {
    text-align: left;
    font-size: 1.2rem;
  }

  .sec .text {
    text-align: left;
    max-width: 80vw;
  }
}

#reveal {
  opacity: 0;
  transition: transform .5s ease-out;
  position: relative;
  transform: translateY(150px);
}

#reveal.active {
  opacity: 1;
  transform: translateY(0);
}
/*# sourceMappingURL=index.19bb4773.css.map */
